import React from "react"
import BootstrapContainer from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

export default function Container({ children, ...props }) {
  return (
    <BootstrapContainer {...props}>
      <Row>
        <Col xs={12} lg={{ span: 10, offset: 1 }} className="main-content-col">
          {children}
        </Col>
      </Row>
    </BootstrapContainer>
  )
}
