import React from "react"
import BaseBlockContent from "@sanity/block-content-to-react"

const serializers = {
  types: {
    block(props) {
      switch (props.node.style) {
        case "h1":
          return <h1 className="mb-5">{props.children}</h1>

        case "h2":
          return <h2 className="mb-4">{props.children}</h2>

        case "h3":
          return <h3 className="mb-3">{props.children}</h3>

        case "h4":
          return <h4 className="mb-2">{props.children}</h4>

        case "blockquote":
          return <blockquote className="mb-2">{props.children}</blockquote>

        default:
          return <p className="">{props.children}</p>
      }
    },
  },
}

const BlockContent = ({ blocks }) => (
  <BaseBlockContent projectId="rkn8y74e" dataset="production" blocks={blocks} serializers={serializers} />
)

export default BlockContent
