/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Link, useStaticQuery, graphql } from "gatsby"
import CookieConsent from "react-cookie-consent"

import Header from "./header"
import Container from "./container"
import "bootstrap/dist/css/bootstrap.min.css"
import "@fortawesome/fontawesome-free/css/fontawesome.css"
import "@fortawesome/fontawesome-free/css/brands.css"
import "@fortawesome/fontawesome-free/css/solid.css"
import "./layout.scss"

import BlockContent from "./block-content"

const Layout = ({ children, page = "", noAlerts = false }) => {
  const {
    site,
    sanityContactSettings: data,
    sanityNewsPageSettings: news,
  } = useStaticQuery(graphql`
    query LayoutRelatedQuery {
      site {
        siteMetadata {
          title
        }
      }
      sanityContactSettings(_id: { eq: "contactSettings" }) {
        address {
          zip
          street
          city
        }
        facebook
        instagram
        phone
        mapsEmbedUrl
        mapsUrl
      }
      sanityNewsPageSettings(_id: { eq: "newsPageSettings" }) {
        showBanner
        _rawBannerText
      }
    }
  `)

  return (
    <Container>
      <Header siteTitle={site.siteMetadata.title} />
      {!noAlerts && news.showBanner && (
        <div className="open-times-info d-flex flex-column flex-md-row">
          <p className="ml-2">
            <BlockContent blocks={news._rawBannerText} />
            <br />
            <div className="button-container">
              <Link className="more-info" to="/aktuelles">
                Aktuelles
              </Link>
            </div>
          </p>
        </div>
      )}
      <main className={page ? `page-${page}` : ""}>{children}</main>
      <footer>
        <div className="social-media">
          <a href={data.facebook} title="@GoschsGasthof">
            <i className="fab fa-facebook-square"></i>
          </a>
          <a href={data.instagram} title="@goschsgasthof">
            <i className="fab fa-instagram"></i>
          </a>
        </div>
        <div className="short-contact-info">
          <a href={`tel:${data.phone}`} className="call" title="Jetzt anrufen">
            <i className="fas fa-phone-alt"></i>
            <span>{data.phone}</span>
          </a>
          <a
            href={data.mapsUrl}
            className="location"
            title="In Google Maps öffnen"
          >
            <i className="fas fa-map-marker-alt"></i>
            <span>
              {data.address.street}, {data.address.zip} {data.address.city}
            </span>
          </a>
        </div>
        <div className="spacer"></div>
        <div className="copyright">
          © {new Date().getFullYear()} Gosch's Gasthof |{" "}
          <Link to="/impressum">Impressum</Link> |{" "}
          <Link to="/datenschutz">Datenschutz</Link>
        </div>
      </footer>
      <CookieConsent
        location="bottom"
        buttonText="Ich stimme zu"
        cookieName="cookie-consent"
        style={{ background: "#223a71", color: "white", fontSize: "13px" }}
        buttonStyle={{
          color: "white",
          background: "#4f84ff",
          fontSize: "13px",
        }}
        expires={150}
      >
        Diese Website verwendet Cookies – nähere Informationen dazu und zu Ihren
        Rechten als Benutzer finden Sie in unserer{" "}
        <Link to="/datenschutz" style={{ color: "#5589ff" }}>
          Datenschutzerklärung
        </Link>{" "}
        . Klicken Sie auf „Ich stimme zu“, um Cookies zu akzeptieren und direkt
        unsere Website besuchen zu können.
      </CookieConsent>
    </Container>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
