import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"

import Collapse from "react-bootstrap/Collapse"

import Logo from "../images/logo.png"

import "./header.scss"

const NavLinks = () => (
  <ul>
    <li>
      <Link to="/" activeClassName="active">
        Home
      </Link>
    </li>
    <li>
      <Link to="/speisekarte" activeClassName="active">
        Speisekarte
      </Link>
    </li>
    <li>
      <Link to="/aktuelles" activeClassName="active">
        Aktuelles
      </Link>
    </li>
    <li>
      <Link to="/galerie" activeClassName="active">
        Galerie
      </Link>
    </li>
    <li>
      <Link to="/kontakt" activeClassName="active">
        Kontakt
      </Link>
    </li>
  </ul>
)

const Header = () => {
  return (
    <div className="main-header">
      <div className="header-logo">
        <a href="/">
          <img src={Logo} alt="Gosch's Gasthof" />
        </a>
      </div>
      <nav className="header-mobile-nav d-flex d-md-none">
        <div>
          <NavLinks />
        </div>
      </nav>
      <nav className="header-nav d-none d-md-flex">
        <NavLinks />
      </nav>
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
